import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/index';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { handleError } from '../../stores/infos/actions';
import withTitle from '../../hocs/withTitle';
import { putCandidate } from '../../stores/candidates/actions';
import { getUsers } from '../../stores/users/actions';
import StringService from '../../services/StringService';
import CustomTable from '../../components/common/table/CustomTable';
import Spinner from '../../components/common/Spinner';
import {
  getCandidaturesForOffer,
  postCandidatureSlots,
  putCandidature,
} from '../../stores/candidatures/actions';
import {
  CANDIDATE_CLIENT,
  CANDIDATE_CONSULT,
  CANDIDATE_PHONE,
  CANDIDATE_VIEW,
  CANDIDATE_VISIO,
} from '../../constants/routes';
import StopModal from '../../components/dialogs/StopDialog';
import ValidateDialog from '../../components/dialogs/ValidateDialog';
import { getOffers } from '../../stores/offers/actions';
import { getVariationTheme } from '../../themes/theme';
import { DEFAULT_GRAY_LIGHT } from '../../themes/defaultColors';
import ApiService from '../../services/ApiService';
import { STEPS } from '../../constants/interviewSteps';
import {
  canLaunchCandidature,
  canValidateOrStopCandidature,
  commentDependingOnCandidatureState,
} from '../../services/candidateService';
import ScoringModal from '../../components/dialogs/ScoringModal';
import RemovedModal from '../../components/dialogs/RemovedModal';

const colorTheme = getVariationTheme();

const styles = (theme) => (
  {
    card: {
      width: 65,
      height: 65,
      borderRadius: 65,
      margin: 20,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    profilDetails: {
      marginLeft: 105,
      marginBottom: 20,
    },
    gridItem: {
      width: '100%',
    },
    gridContainer: {
      width: '100%',
      borderLeft: '1px solid',
      borderLeftColor: DEFAULT_GRAY_LIGHT,
    },
    container: {
      height: 'calc(100vh - 68px)',
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    button: {
      position: 'absolute',
      right: 10,
    },
    buttonCV: {
      margin: 20,
      height: 50,
      backgroundColor: colorTheme.text_light,
      width: 'calc(100% - 40px)',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    textField: {
      width: 500,
    },
    dateField: {
      marginBottom: 10,
    },
  }
);

// Intial State of the Component
const INITIAL_STATE = {
  id: null,
  selectedTab: 0,
  datas: [],
  numPages: null,
  pageNumber: 1,
  openPDFModal: false,
  openCommentModal: false,
  anchor: null,
  optionsElement: null,
  openScoringModal: false,
  openValidateModal: false,
  step: 0,
  openStopModal: false,
  openRemovedModal: false,
};

const stateWithOffer = (offer) => (
  {
    id: offer.id,
    candidate: offer.candidate,
  }
);

export class OfferPage extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    handleError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (props.authUser && !props.offers && !props.pending) {

      props.authUser.getIdToken(true).then(token => {
        props.getOffers(token);
      });
    }
    if (!props.users && !props.pending) {
      props.getUsers();
    }
    if (props.offers && props.candidatures) {
      if (state.datas === INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.candidatures,
        };
      }
      if (props.candidatures !== state.datas && state.datas !== INITIAL_STATE.datas) {
        newState = {
          ...newState,
          datas: props.candidatures,
        };
      }
    }

    if (props.offers
      && props.match
      && props.match.params.offerId
      && state.id
      === INITIAL_STATE.id) {

      const offers = props.offers;
      const currentOfferId = props.match.params.offerId;
      const foundOffer = offers.find(offer => offer.id === currentOfferId);
      if (foundOffer) {

        props.authUser.getIdToken(true).then(token => {
          props.getCandidaturesForOffer(token, foundOffer);
        });
        newState = { ...newState, ...stateWithOffer(foundOffer) };
      }
    }
    return newState;
  }

  handleToggleScoringModal = event => {
    event.stopPropagation();
    this.setState({ openScoringModal: !this.state.openScoringModal });
  };
  handleToggleValidateModal = event => {
    event.stopPropagation();
    this.setState({ openValidateModal: !this.state.openValidateModal });
  };
  handleToggleStopModal = event => {
    event.stopPropagation();
    this.setState({ openStopModal: !this.state.openStopModal });
  };
  handleToggleRemovedModal = () => {
    this.setState({ openRemovedModal: !this.state.openRemovedModal });
  };

  handleUpdateScoring = event => {

    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putCandidature(
        token,
        this.state.optionsElement.id,
        { rate: this.state.rate },
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
    this.handleToggleScoringModal(event);
  };

  handleValidate = event => {
    let newSlots = [];
    if (!this.state.step) {
      this.props.handleError({ message: 'Veuillez sélectionner une nouvelle étape.' });
      return;
    }
    if (!this.state.slot_1) {
      this.props.handleError({ message: 'Veuillez sélectionner un créneau. Attention à bien préciser l\'heure.' });
      return;
    }
    if (this.state.slot_1) {
      newSlots.push({ date: this.state.slot_1 });
    }
    if (this.state.slot_2) {
      newSlots.push({ date: this.state.slot_2 });
    }
    if (this.state.slot_3) {
      newSlots.push({ date: this.state.slot_3 });
    }
    const candidatureState = commentDependingOnCandidatureState(
      this.state.optionsElement.state,
      this.state.comment,
    );

    this.props.authUser.getIdToken(true).then(token => {
      return this.props.postCandidatureSlots(
        token,
        this.state.optionsElement.id,
        {
          state: this.state.step,
          slots: newSlots,
          ...candidatureState,
        },
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
    this.handleToggleValidateModal(event);
  };

  handleStop = event => {

    this.props.authUser.getIdToken(true).then(token => {
      return this.props.putCandidature(
        token,
        this.state.optionsElement.id,
        {
          state: STEPS.CANDIDATE_REJECTED.value,
          consult_reject_reason: this.state.consult_reject_reason,
          consult_reject_comment: this.state.consult_reject_comment,
        },
      );
    })
      .catch((error) => {
        this.props.handleError(error);
      });
    this.handleToggleStopModal(event);
  };

  handleSelect = (event, element) => {
    event.stopPropagation();
    this.setState({
      anchor: event.currentTarget,
      optionsElement: element,
      step: element.state,
    });
  };

  handleClickRow = (element) => {
    if (element.state === STEPS.CANDIDATE_REMOVED.value) {
      this.setState({
        optionsElement: element,
        step: element.state,
      });
      this.handleToggleRemovedModal();
    } else {
      this.props.history.push(CANDIDATE_VIEW.replace(':candidateId', element.candidate.id));
    }
  };
  handleSendReminderMail = (candidature) => {
    this.props.authUser.getIdToken(true)
      .then(token => {
        ApiService.postCandidaturesReminder(token, candidature);
      })
      .finally(() => {
        this.setState({ anchor: null });

      });
  };

  replaceRoutesParams = (route) => {
    return route
      .replace(':candidateId', this.state.optionsElement.candidate.id)
      .replace(':candidatureId', this.state.optionsElement.id);
  };

  handleLaunchStepCandidature = () => {
    const historyVariables = {
      candidature: this.state.optionsElement,
      candidate: this.state.optionsElement.candidate,
    };
    // eslint-disable-next-line
    switch (this.state.step) {
      case STEPS.PHONE_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_PHONE),
          historyVariables,
        );
      case STEPS.VISIO_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_VISIO),
          historyVariables,
        );
      case STEPS.CONSULT_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_CONSULT),
          historyVariables,
        );
      case STEPS.CLIENT_VALIDATED.value:
        return this.props.history.push(
          this.replaceRoutesParams(CANDIDATE_CLIENT),
          historyVariables,
        );
    }
  };

  handleValidateCandidature = event => {
    this.setState({ anchor: null });
    this.handleToggleValidateModal(event);
  };

  handleStopCandidature = event => {
    this.setState({ anchor: null });
    this.handleToggleStopModal(event);
  };

  handleModifyScoringCandidature = event => {
    this.setState({ anchor: null });
    this.handleToggleScoringModal(event);
  };

  render() {
    const {
      classes,
    } = this.props;

    const disabledButton = !canValidateOrStopCandidature(this.state.optionsElement);
    const columnData = [
      {
        id: 'candidate.firstname',
        label: 'Prénom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'candidate.lastname',
        label: 'Nom',
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'created_at',
        label: 'Date de candidature',
        date: true,
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'state',
        label: 'Etape de candidature',
        step: true,
        align: 'left',
        disablePadding: false,
      },
      {
        id: 'scoring',
        label: 'Score',
        step: true,
        align: 'left',
        disablePadding: false,
      },
    ];

    const optionsMenu = (
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchor}
        keepMounted
        open={Boolean(this.state.anchor)}
        onClose={() => this.setState({ anchor: null })}
      >
        {this.state.optionsElement?.candidate?.firebase_uid === null && (
          <MenuItem
            onClick={event => {
              event.stopPropagation();
              this.handleSendReminderMail(this.state.optionsElement);
            }}
          >
            Renvoyer le mail au candidat
          </MenuItem>
        )}
        <MenuItem
          disabled={!canLaunchCandidature(this.state.optionsElement)}
          onClick={event => this.handleLaunchStepCandidature(event)}
        >Lancer l'étape</MenuItem>
        <MenuItem
          onClick={event => this.handleModifyScoringCandidature(event)}
        >Modifier le scoring</MenuItem>
        <MenuItem
          disabled={disabledButton}
          onClick={event => this.handleValidateCandidature(event)}
        >
          Valider la candidature
        </MenuItem>
        <MenuItem
          disabled={disabledButton}
          onClick={event => this.handleStopCandidature(event)}
        >
          Refuser la candidature
        </MenuItem>
      </Menu>
    );

    return (
      <Grid container className={classes.container}>
        <Grid
          container xs={12} sm={12} md={12}
          direction="column"
          className={classes.gridContainer}
        >
          <Grid className={classes.gridItem}>
            <Typography
              variant="subtitle1" style={{
              fontWeight: 'bold',
              margin: 20,
            }}
            >
              Liste des candidatures
            </Typography>
            <div style={{ margin: 20 }}>
              {(
                this.props.pending || this.props.sending
              ) && (
                <Spinner />
              )}
              {!this.props.pending && !this.props.sending && (
                <CustomTable
                  datas={this.state.datas.map(d => (
                    {
                      ...d,
                      state: (
                        d.candidate
                        && d.candidate.firebase_uid
                        && d.candidate.firebase_uid !== null
                      )
                        ? d.state
                        : -1,
                    }
                  ))}
                  emptyDataMessage="Aucune Candidature"
                  columnData={columnData}
                  sending={this.props.sending}
                  onOptions={this.handleSelect}
                  onClickRow={this.handleClickRow}
                  showDelete={false}
                  defaultOrderBy={'scoring'}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <StopModal
          openStopModal={this.state.openStopModal}
          handleToggleStopModal={this.handleToggleStopModal}
          handleReason={(number, reason, comment) =>
            this.setState({
              stop: number,
              consult_reject_reason: reason,
              consult_reject_comment: comment,
            })}
          consultRejectComment={this.state.consult_reject_comment}
          stop={this.state.stop}
          handleComment={
            (event) => this.setState({ consult_reject_comment: event.target.value })
          }
          handleStop={(event) => this.handleStop(event)}
        />
        <ValidateDialog
          openValidateModal={this.state.openValidateModal}
          handleToggleValidateModal={this.handleToggleValidateModal}
          candidature={this.state.optionsElement}
          step={this.state.step}
          handleStepPhone={() => this.setState({ step: STEPS.PHONE_WAITING_SLOTS.value })}
          handleStepVisio={() => this.setState({ step: STEPS.VISIO_WAITING_SLOTS.value })}
          handleStepConsult={() => this.setState({ step: STEPS.CONSULT_WAITING_SLOTS.value })}
          handleStepClient={() => this.setState({ step: STEPS.CLIENT_WAITING_SLOTS.value })}
          comment={this.state.comment}
          handleFirstSlot={event => this.setState({
            slot_1: StringService.dateTimeStringToTimestamp(event.target.value),
          })}
          handleSecondSlot={event => this.setState({
            slot_2: StringService.dateTimeStringToTimestamp(event.target.value),
          })}
          handleThirdSlot={event => this.setState({
            slot_3: StringService.dateTimeStringToTimestamp(event.target.value),
          })}
          handleValidate={this.handleValidate}
          handleMessage={event => this.setState({ comment: event.target.value })}
        />
        <ScoringModal
          openScoringModal={this.state.openScoringModal}
          candidature={this.state.optionsElement}
          onChange={(event, value) => this.setState({ rate: value })}
          handleToggleScoringModal={this.handleToggleScoringModal}
          handleUpdateScoring={this.handleUpdateScoring}
        />
        <RemovedModal
          openRemovedModal={this.state.openRemovedModal}
          handleToggleRemovedModal={this.handleToggleRemovedModal}
          candidature={this.state.optionsElement}
          classes={classes}
        />
        {optionsMenu}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.content,
    authUser: state.users.authUser,
    pending: state.offers.pending || state.candidatures.pending || state.users.pending,
    sending: state.offers.sending || state.candidatures.sending,
    offers: state.offers.content,
    candidatures: state.candidatures.content,
  };
}

export default compose(
  withStyles(styles),
  withTitle({
    title: 'Offre',
    subtitle: 'Accueil/Offre/Liste des candidatures',
  }),
  connect(mapStateToProps, {
    getOffers,
    putCandidate,
    getCandidaturesForOffer,
    putCandidature,
    postCandidatureSlots,
    getUsers,
    handleError
  }),
)(OfferPage);
